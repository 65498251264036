import { fromJS } from 'immutable'
import * as actionTypes from './constants'

const getToken = () => {
  if (typeof localStorage !== 'undefined') {
    // needed for test
    const tokenSource = localStorage.getItem('dtToken')
    if (!tokenSource) return null
    const token = JSON.parse(tokenSource)
    if (new Date(Number(token.expires_at)) > Date.now()) {
      return token
    }
    localStorage.removeItem('dtToken')
  }
  return null
}

const getUserDataEncryptionKey = () => {
  if (typeof localStorage !== 'undefined') {
    // needed for test
    if (localStorage && localStorage.userDataEncryptionKey) {
      return localStorage.getItem('userDataEncryptionKey')
    }
  }
  return undefined
}

const initialState = fromJS({
  email: '',
  password: '',
  twoFaCode: '',
  hideMyProjects: false,
})
  .set('token', fromJS(getToken()))
  .set('userDataEncryptionKey', getUserDataEncryptionKey())

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESSFUL:
      if (typeof localStorage !== 'undefined') {
        // needed for test
        const { token } = action
        token.expires_at = Date.now() + Number(action.token.expires_in) * 1000
        localStorage.setItem('dtToken', JSON.stringify(token))
      }
      return state
        .withMutations((s) =>
          s
            .set('token', fromJS(action.token))
            .set('password', '')
            .set('twoFaCode', '')
            .set('loginError', null)
            .set('show2FA', undefined)
            .set('expiredToken', false)
            .set('showLoginPopup', false),
        )
        .set('loggingIn', false)
    case actionTypes.LOGIN_ERROR:
      return state.set('loginError', action.error).set('loggingIn', false)
    case actionTypes.LOGIN:
      return state.set('loginError', null).set('loggingIn', true)
    case actionTypes.LOGOUT:
      if (typeof sessionStorage !== 'undefined') {
        // needed for test
        localStorage.removeItem('userDataEncryptionKey') // remove encryption key if it's was generated
        if (state.get('token')) {
          localStorage.setItem('docuToolsLogout', Date.now())
          localStorage.removeItem('dtToken')
        }
      }
      return state
        .set('token', null)
        .delete('userDataEncryptionKey')
        .set('user', null)
        .set('password', '')
        .set('twoFaCode', '')
        .set('loginError', null)
        .set('show2FA', undefined)
        .delete('passwordPolicy')
        .set('expiredToken', false)
        .set('showLoginPopup', false)
    case actionTypes.GET_USER:
      return state.set('gettingUser', true)
    case actionTypes.GET_USER_DONE:
      return state.set('gettingUser', false)
    case actionTypes.SET_USER: {
      const { user } = action
      return state
        .set('user', fromJS(user))
        .set(
          'hideMyProjects',
          !!localStorage.getItem(`hideMyProjects-${user.userId}`),
        )
        .set('email', user.username)
        .set('userType', user.type)
        .set('serviceFlags', user.serviceFlags)
    }
    case actionTypes.UPDATE_USER: {
      const user = {
        ...state.get('user')?.toJS(),
        ...action.user,
      }

      let newState = state

      if (action.user.username) {
        newState = newState.set('email', action.user.username)
      }

      return newState
        .set('user', fromJS(user))
        .set(
          'hideMyProjects',
          !!localStorage.getItem(`hideMyProjects-${user.userId}`),
        )
    }
    case actionTypes.SET_EMAIL:
      return state.set('email', action.email)
    case actionTypes.SET_PASSWORD:
      return state.set('password', action.password)
    case actionTypes.SET_TWOFA_CODE:
      return state.set('twoFaCode', action.twoFaCode).set('loginError', null)
    case actionTypes.SHOW_2FA:
      return state.set('show2FA', action.status)
    case actionTypes.SET_PASSWORD_POLICY:
      return state.set('passwordPolicy', action.status)
    case actionTypes.BACK_TO_LOGIN:
      return state.set('show2FA', undefined)

    // verify email
    case actionTypes.VERIFY_EMAIL:
      return state.set('emailVerifying', true)
    case actionTypes.VERIFY_EMAIL_DONE:
      return state.set('emailVerifying', false).set('emailVerified', true)
    case actionTypes.VERIFY_EMAIL_WRONG_EMAIL:
      return state.set('emailVerifying', false).set('verifyEmailWrong', true)
    case actionTypes.VERIFY_EMAIL_ERROR:
      return state.set('emailVerifying', false).set('loginError', action.error)

    case actionTypes.LOGIN_VIA_SAML:
      return state.delete('loginViaSamlError').set('loggingInViaSaml', true)
    case actionTypes.LOGIN_VIA_SAML_ERROR:
      return state
        .set('loginViaSamlError', action.error)
        .set('loggingInViaSaml', false)
    case actionTypes.LOGIN_VIA_SAML_DONE:
      return state.set('loggingInViaSaml', false)
    case actionTypes.GET_ENCRYPTION_KEY_FOR_USER_DATA:
      return state
        .set('gettingEncryptionKeyForUserData', true)
        .delete('userDataEncryptionKey')
        .delete('getUserDataEncryptionKeyError')
    case actionTypes.GET_ENCRYPTION_KEY_FOR_USER_DATA_DONE:
      localStorage.setItem('userDataEncryptionKey', action.key)
      return state
        .set('gettingEncryptionKeyForUserData', false)
        .set('userDataEncryptionKey', action.key)
    case actionTypes.GET_ENCRYPTION_KEY_FOR_USER_DATA_ERROR:
      return state
        .set('gettingEncryptionKeyForUserData', false)
        .set('getUserDataEncryptionKeyError', action.error)
    case actionTypes.HIDE_MY_PROJECTS:
      localStorage.setItem(
        `hideMyProjects-${state.get('user').get('userId')}`,
        'true',
      )
      return state.set('hideMyProjects', true)
    case actionTypes.SHOW_MY_PROJECTS:
      localStorage.removeItem(
        `hideMyProjects-${state.get('user').get('userId')}`,
      )
      return state.set('hideMyProjects', false)
    case actionTypes.LOGIN_WITH_REDIRECT_SUCCESSFUL:
      return state
        .set('redirect_token', action.token)
        .set('password', '')
        .set('twoFaCode', '')
        .set('loginError', null)
        .set('show2FA', undefined)

    case actionTypes.EXPIRED_TOKEN: {
      return state.set('expiredToken', action.status)
    }
    case actionTypes.SHOW_LOGIN_POPUP: {
      return state.set('showLoginPopup', action.status)
    }
    case actionTypes.GET_TOKEN_BY_REFRESH_TOKEN_DONE: {
      const token = state.get('token')
      return state.set('token', fromJS({ ...token, ...action.token }))
    }
    default:
      return state
  }
}

export default reducer
