/* global Sentry */
import PropTypes from 'prop-types'
import { Component, lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { injectIntl } from 'react-intl'

import { PageLoading, PopupLoading } from '../../components/AsyncLoader'
import Content from './Content'
import AppDiv from './AppDiv'
import Wrapper from './Wrapper'
import {
  makeSelectExpiredToken,
  makeSelectGettingEncryptionKeyForUserData,
  makeSelectGettingUser,
  makeSelectHideMyProjects,
  makeSelectLicense,
  makeSelectLoggedIn,
  makeSelectloggingIn,
  makeSelectPasswordPolicy,
  makeSelectPermissions,
  makeSelectPrivacyPolicyAccepted,
  makeSelectShowLoginPopup,
  makeSelectTermsAndConditionsAccepted,
  makeSelectTokenAvailable,
  makeSelectUser,
  makeSelectUserAvailable,
  makeSelectUserDataEncryptionKeyAvailable,
  makeSelectUserId,
} from '../Authentication/selectors'
import * as selectors from './selectors'
import * as actions from './actions'
import { getTokenByRefreshToken, getUser } from '../Authentication/actions'
import { loadOwnProjects } from '../ProjectSelection/actions'
import { Loader, LoaderWrapper } from '../../components/PageLoader'
import ProjectRouter from './ProjectRouter'
import { Page } from './styledComponents'
import PopupNew from '../../components/PopupNew'
import ForceLogin from '../Authentication/ForceLogin'
import ForceLogout from '../Authentication/ForceLogout'
// routes
import NotFoundPage from '../NotFoundPage'
import JavascriptErrorPage from '../JavascriptErrorPage'
import PopupOverlay, { MobilePopupOverlay } from './PopupOverlay'
import { type } from '../BottomBar/constants'
import { isActivePath, pageType } from '../../utils/isActivePath'

// We want those to be included in the main bundle to prevent extra requests
import Login from '../Authentication/Login'
import { makeSelectProjectDetails } from '../ProjectSettings/selectors'
import { makeSelectPlan } from '../Plan/selectors'
import { makeSelectPin } from '../PinDetails/selectors'

const ONBOARDING_COMPLETE_KEY = 'onboardingComplete'

// TODO error boundary on lazy loading error

const NavigationSidebar = lazy(
  () =>
    import(/* webpackChunkName: "NavigationSidebar" */ '../NavigationSidebar'),
)
const LoginPopup = lazy(
  () => import(/* webpackChunkName: "Login" */ '../Authentication/LoginPopup'),
)
const SamlLogin = lazy(
  () =>
    import(/* webpackChunkName: "SamlLogin" */ '../Authentication/SamlLogin'),
)
const IdpSelection = lazy(
  () =>
    import(
      /* webpackChunkName: "IdpSelection" */ '../Authentication/IdpSelection'
    ),
)
const UserSettings = lazy(
  () => import(/* webpackChunkName: "UserSettings" */ '../UserSettings'),
)
const UserManagement = lazy(
  () => import(/* webpackChunkName: "UserManagement" */ '../UserManagement'),
)
const Reports = lazy(
  () => import(/* webpackChunkName: "Reports" */ '../Reports'),
)
const ImportQuickView = lazy(
  () => import(/* webpackChunkName: "ImportQuickView" */ '../ImportQuickView'),
)
const ProjectSelection = lazy(
  () =>
    import(/* webpackChunkName: "ProjectSelection" */ '../ProjectSelection'),
)
const ActivateUser = lazy(
  () => import(/* webpackChunkName: "ActivateUser" */ '../ActivateUser'),
)
const ForgotPassword = lazy(
  () => import(/* webpackChunkName: "ForgotPassword" */ '../ForgotPassword'),
)
const ImportStatus = lazy(
  () => import(/* webpackChunkName: "ImportStatus" */ '../ImportStatus'),
)
const TopBar = lazy(() => import(/* webpackChunkName: "TopBar" */ '../TopBar'))
const BottomBar = lazy(
  () => import(/* webpackChunkName: "BottomBar" */ '../BottomBar'),
)
const TermsAndConditionWarning = lazy(
  () =>
    import(
      /* webpackChunkName: "TermsAndConditionWarning" */ './TermsAndConditionWarning'
    ),
)
const EnableTwoFa = lazy(
  () => import(/* webpackChunkName: "EnableTwoFa" */ './EnableTwoFa'),
)
const OnboardingPopup = lazy(
  () =>
    import(
      /* webpackChunkName: "OnboardingPopup" */ '../../components/OnboardingPopup'
    ),
)

export class App extends Component {
  constructor(props) {
    super(props)
    if (
      props.tokenAvailable &&
      props.userDataEncryptionKeyAvailable &&
      !props.userAvailable
    ) {
      props.getUser()
    }

    window.addEventListener(
      'resize',
      () => props.resize(window.innerWidth, window.innerHeight),
      { passive: true },
    )

    this.state = {
      props: {
        tokenAvailable: props.tokenAvailable,
        userDataEncryptionKeyAvailable: props.userDataEncryptionKeyAvailable,
        userAvailable: props.userAvailable,
      },
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { props: prevProps } = prevState
    if (
      !nextProps.gettingUser &&
      nextProps.tokenAvailable &&
      nextProps.userDataEncryptionKeyAvailable &&
      !nextProps.userAvailable &&
      (!prevProps.tokenAvailable || !prevProps.userDataEncryptionKeyAvailable)
    ) {
      nextProps.getUser()
    }
    return null
  }

  componentDidUpdate(prevProps) {
    const {
      tokenAvailable,
      userDataEncryptionKeyAvailable,
      userId,
      hideMyProjects,
      loadOwnProjects: loadOwnProjectsProp,
      getTokenByRefreshToken: getTokenByRefreshTokenProp,
      closeMenu,
      windowWidth,
      expiredToken,
      location,
      projectDetails,
      plan,
      pin,
      user,
    } = this.props
    if (
      ((!prevProps.tokenAvailable && tokenAvailable) ||
        (!prevProps.userDataEncryptionKeyAvailable &&
          userDataEncryptionKeyAvailable)) &&
      tokenAvailable &&
      userDataEncryptionKeyAvailable &&
      userId &&
      !hideMyProjects
    ) {
      this.showOnboardingPopup()
      loadOwnProjectsProp()
    }
    if (
      ((!prevProps.userId && userId && userDataEncryptionKeyAvailable) ||
        (userId &&
          !prevProps.userDataEncryptionKeyAvailable &&
          userDataEncryptionKeyAvailable)) &&
      !hideMyProjects
    ) {
      this.showOnboardingPopup()
      loadOwnProjectsProp()
    }
    if (!prevProps.expiredToken && expiredToken) {
      getTokenByRefreshTokenProp()
    }
    if (prevProps.location !== location && windowWidth <= 1024) {
      closeMenu()
    }

    if (
      !['production', 'strabag'].includes(import.meta.env.VITE_TARGET_ENV) &&
      !window.forceFailNextSync
    ) {
      window.forceFailNextSync = () => {
        // eslint-disable-next-line no-console
        console.log(
          'Next sync will fail. The prophecy shall come true. Beware for its unraveling is nigh.',
        )
      }
    }

    // set the sentry scope when the project plan or pin changes
    if (typeof Sentry !== 'undefined' && Sentry?.configureScope) {
      Sentry.configureScope((scope) => {
        if (
          projectDetails &&
          projectDetails.id !== prevProps.projectDetails?.id
        ) {
          scope.setTag('project_name', projectDetails?.name)
          scope.setTag('project_id', projectDetails?.id.replace(/-/g, ''))
        }
        if (plan && plan.id !== prevProps.plan?.id) {
          scope.setTag('plan_id', plan?.id.replace(/-/g, ''))
          scope.setTag('plan_name', plan?.name)
        }
        if (pin && pin.id !== prevProps.pin?.id) {
          scope.setTag('pin_id', pin?.id.replace(/-/g, ''))
          scope.setTag('pin_name', pin?.name)
        }
        if (user && user.email !== prevProps.user?.email) {
          scope.setUser({ email: user.email })
        }
      })
    }
  }

  showOnboardingPopup = () => {
    const { userId } = this.props
    const onboardingComplete = Boolean(
      localStorage.getItem(`${ONBOARDING_COMPLETE_KEY}--${userId}`),
    )
    if (!onboardingComplete) {
      this.setState({ showOnboardingPopup: true })
    }
  }

  hideOnboardingPopup = () => {
    const { userId } = this.props
    localStorage.setItem(`${ONBOARDING_COMPLETE_KEY}--${userId}`, true)
    this.setState({ showOnboardingPopup: false })
  }

  handleRemoveState = () => {
    const {
      history: { push },
      location: { pathname },
    } = this.props
    push({ pathname })
    window.location.reload()
  }

  componentDidCatch(error, errorInfo) {
    const { hidePopupOverlay, hideMobilePopupOverlay, history, location } =
      this.props

    const sentryPromise = ['production', 'staging', 'strabag'].includes(
      import.meta.env.VITE_TARGET_ENV,
    )
      ? import('@sentry/react')
      : Promise.resolve(null)

    sentryPromise.then((Sentry) =>
      Sentry
        ? Sentry.withScope((scope) => {
            Object.entries(errorInfo).forEach(([key, value]) =>
              scope.setExtra(key, value),
            )
            Sentry.captureException(error)
          })
        : console.log(error),
    ) // eslint-disable-line no-console
    hidePopupOverlay()
    hideMobilePopupOverlay()
    history.push('/javascriptError', {
      error: error && error.message,
      previousPath: location.pathname,
    })
  }

  render() {
    const {
      loggedIn,
      userDataEncryptionKeyAvailable,
      gettingEncryptionKeyForUserData,
      tokenAvailable,
      showPopupOverlay,
      showMobilePopupOverlay,
      menuOpen,
      toggleMenu,
      closeMenu,
      currentRoute,
      currentRouteHref,
      isDesktop,
      isMobile,
      passwordPolicy,
      license,
      match,
      history,
      privacyPolicyAccepted,
      termsAndConditionsAccepted,
      showLoginPopup,
      location,
      showBottomBar,
      user,
      browserInfo,
      loggingIn,
      intl: { formatMessage },
    } = this.props

    const { showOnboardingPopup } = this.state

    const loggedInAndEncryptionKeyReady =
      loggedIn && userDataEncryptionKeyAvailable
    const topBarHeight = loggedInAndEncryptionKeyReady && !isMobile ? 50 : 0
    let sideBarWidth = loggedInAndEncryptionKeyReady ? 56 : 0
    if (loggedInAndEncryptionKeyReady && isDesktop && menuOpen) {
      sideBarWidth = 250
    } else if (loggedInAndEncryptionKeyReady && isMobile) {
      sideBarWidth = 0
    }

    const pPTaC = privacyPolicyAccepted && termsAndConditionsAccepted

    const bottomBarItems = [
      {
        type: type.PROJECTS,
        title: 'projects_projects',
        onClick: () => history.push('/'),
        active: isActivePath(pageType.PROJECTS, match),
      },
    ]

    const showEnableTwoFa =
      loggedInAndEncryptionKeyReady &&
      passwordPolicy &&
      !passwordPolicy.sms &&
      user &&
      user.forceMfa &&
      user.type !== 'SAML'

    return (
      <AppDiv overflowHidden={loggedInAndEncryptionKeyReady}>
        {loggedInAndEncryptionKeyReady && !pPTaC && (
          <Suspense fallback={PopupLoading}>
            <TermsAndConditionWarning />
          </Suspense>
        )}
        {showEnableTwoFa && (
          <Suspense fallback={PopupLoading}>
            <EnableTwoFa />
          </Suspense>
        )}
        {showLoginPopup && (
          <Suspense fallback={PopupLoading}>
            <LoginPopup
              match={match}
              location={location}
              descriptionId="loginPopup_tokenExpiredDescription"
            />
          </Suspense>
        )}
        {showOnboardingPopup && (
          <Suspense fallback={PopupLoading}>
            <OnboardingPopup closePopup={this.hideOnboardingPopup} />
          </Suspense>
        )}
        {/* If the user does not have permission for the project */}
        {history.location.state?.data === 'permission denied' && (
          <Suspense fallback={PopupLoading}>
            <PopupNew
              title={formatMessage({ id: 'access_denied_heading' })}
              contentText={formatMessage({ id: 'access_denied_description' })}
              rightButtons={[
                {
                  onClick: this.handleRemoveState,
                  text: formatMessage({ id: 'ok' }),
                },
              ]}
            />
          </Suspense>
        )}
        {(tokenAvailable && loggingIn) || gettingEncryptionKeyForUserData ? (
          <LoaderWrapper>
            <Loader className="loader" />
          </LoaderWrapper>
        ) : (
          <Page visible>
            {loggedInAndEncryptionKeyReady && !isMobile && (
              <Suspense fallback={null}>
                <TopBar
                  termsAndConditionsAccepted={pPTaC}
                  currentRoute={currentRoute}
                  currentRouteHref={currentRouteHref}
                  isMobile={isMobile}
                  height={topBarHeight}
                  loggedIn={loggedIn}
                  toggleMenu={toggleMenu}
                  menuOpen={menuOpen}
                  license={license}
                  history={history}
                />
              </Suspense>
            )}
            <Wrapper topHeight={topBarHeight}>
              {loggedInAndEncryptionKeyReady && (
                <Switch>
                  <Route path="/project/settings/:projectId">
                    <Suspense fallback={null}>
                      <NavigationSidebar
                        showPopupOverlay={showPopupOverlay}
                        open={menuOpen}
                        loggedIn={loggedIn}
                        toggleMenu={toggleMenu}
                        closeMenu={closeMenu}
                        currentRoute={currentRoute}
                        isMobile={isMobile}
                        isDesktop={isDesktop}
                      />
                    </Suspense>
                  </Route>
                  <Route path="/project/move">
                    <Suspense fallback={null}>
                      <NavigationSidebar
                        showPopupOverlay={showPopupOverlay}
                        open={menuOpen}
                        loggedIn={loggedIn}
                        toggleMenu={toggleMenu}
                        closeMenu={closeMenu}
                        currentRoute={currentRoute}
                        isMobile={isMobile}
                        isDesktop={isDesktop}
                      />
                    </Suspense>
                  </Route>
                  <Route path="/project/new/:parentId?">
                    <Suspense fallback={null}>
                      <NavigationSidebar
                        showPopupOverlay={showPopupOverlay}
                        open={menuOpen}
                        loggedIn={loggedIn}
                        toggleMenu={toggleMenu}
                        closeMenu={closeMenu}
                        currentRoute={currentRoute}
                        isMobile={isMobile}
                        isDesktop={isDesktop}
                      />
                    </Suspense>
                  </Route>
                  <Route path="/project/newfolder/:parentId?">
                    <Suspense fallback={null}>
                      <NavigationSidebar
                        showPopupOverlay={showPopupOverlay}
                        open={menuOpen}
                        loggedIn={loggedIn}
                        toggleMenu={toggleMenu}
                        closeMenu={closeMenu}
                        currentRoute={currentRoute}
                        isMobile={isMobile}
                        isDesktop={isDesktop}
                      />
                    </Suspense>
                  </Route>
                  <Route path="/project/rename/:folderId?">
                    <Suspense fallback={null}>
                      <NavigationSidebar
                        showPopupOverlay={showPopupOverlay}
                        open={menuOpen}
                        loggedIn={loggedIn}
                        toggleMenu={toggleMenu}
                        closeMenu={closeMenu}
                        currentRoute={currentRoute}
                        isMobile={isMobile}
                        isDesktop={isDesktop}
                      />
                    </Suspense>
                  </Route>
                  <Route path="/project/:projectId">
                    <Suspense fallback={null}>
                      <NavigationSidebar
                        showPopupOverlay={showPopupOverlay}
                        open={menuOpen}
                        loggedIn={loggedIn}
                        toggleMenu={toggleMenu}
                        closeMenu={closeMenu}
                        currentRoute={currentRoute}
                        isMobile={isMobile}
                        isDesktop={isDesktop}
                      />
                    </Suspense>
                  </Route>
                  <Route>
                    <Suspense fallback={null}>
                      <NavigationSidebar
                        showPopupOverlay={showPopupOverlay}
                        open={menuOpen}
                        loggedIn={loggedIn}
                        toggleMenu={toggleMenu}
                        closeMenu={closeMenu}
                        currentRoute={currentRoute}
                        isMobile={isMobile}
                        isDesktop={isDesktop}
                      />
                    </Suspense>
                  </Route>
                </Switch>
              )}
              <Content
                left={sideBarWidth}
                overflowHidden={match.url.indexOf('/lockScroll') > -1}
              >
                <MobilePopupOverlay visible={showMobilePopupOverlay} />
                {browserInfo && browserInfo.os !== 'iOS' && (
                  <PopupOverlay
                    visible={
                      (menuOpen &&
                        !isDesktop &&
                        loggedInAndEncryptionKeyReady) ||
                      showPopupOverlay
                    }
                  />
                )}
                <Suspense fallback={PageLoading}>
                  <Switch>
                    <Route path="/javascriptError">
                      <JavascriptErrorPage />
                    </Route>

                    <Route exact path="/samlLogin">
                      <SamlLogin />
                    </Route>
                    <Route exact path="/idpSelection">
                      <IdpSelection />
                    </Route>

                    {/* login required */}
                    <Route exact path="/home/:mode/:token?">
                      <ForceLogin>
                        <ProjectSelection />
                      </ForceLogin>
                    </Route>
                    <Route exact path="/home">
                      <ForceLogin>
                        <ProjectSelection />
                      </ForceLogin>
                    </Route>
                    <Route exact path="/project">
                      <ForceLogin>
                        <ProjectSelection />
                      </ForceLogin>
                    </Route>
                    <Route exact path="/">
                      <ForceLogin>
                        <ProjectSelection />
                      </ForceLogin>
                    </Route>
                    <Route exact path="/importStatus">
                      <ForceLogin>
                        <ProjectSelection />
                      </ForceLogin>
                    </Route>
                    <Route path="/project/settings/:projectId">
                      <ForceLogin>
                        <ProjectSelection />
                      </ForceLogin>
                    </Route>
                    <Route path="/project/new/:parentId?">
                      <ForceLogin>
                        <ProjectSelection />
                      </ForceLogin>
                    </Route>
                    <Route path="/project/move">
                      <ForceLogin>
                        <ProjectSelection />
                      </ForceLogin>
                    </Route>
                    <Route path="/project/newfolder/:parentId?">
                      <ForceLogin>
                        <ProjectSelection />
                      </ForceLogin>
                    </Route>
                    <Route path="/project/rename/:folderId?">
                      <ForceLogin>
                        <ProjectSelection />
                      </ForceLogin>
                    </Route>
                    <Route path="/project/importStatus">
                      <ForceLogin>
                        <ProjectSelection />
                      </ForceLogin>
                    </Route>
                    <Route path="/project/:projectId/copy">
                      <ForceLogin>
                        <ProjectSelection />
                      </ForceLogin>
                    </Route>
                    <Route path="/project/:projectId">
                      <ForceLogin>
                        <ProjectRouter />
                      </ForceLogin>
                    </Route>
                    <Route exact path="/userSettings">
                      <ForceLogin>
                        <UserSettings />
                      </ForceLogin>
                    </Route>
                    <Route exact path="/userSettings/importStatus">
                      <ForceLogin>
                        <UserSettings />
                      </ForceLogin>
                    </Route>
                    <Route path="/userManagement">
                      <ForceLogin>
                        <UserManagement />
                      </ForceLogin>
                    </Route>
                    <Route path="/userManagement/importStatus">
                      <ForceLogin>
                        <UserManagement />
                      </ForceLogin>
                    </Route>
                    <Route path="/reports">
                      <ForceLogin>
                        <Reports />
                      </ForceLogin>
                    </Route>
                    <Route path="/reports/importStatus">
                      <ForceLogin>
                        <Reports />
                      </ForceLogin>
                    </Route>

                    {/* logout required */}
                    <Route exact path="/activate/:type/:token">
                      <ForceLogout>
                        <ActivateUser />
                      </ForceLogout>
                    </Route>
                    <Route exact path="/forgotPassword">
                      <ForceLogout>
                        <ForgotPassword />
                      </ForceLogout>
                    </Route>

                    <Route>
                      <NotFoundPage />
                    </Route>
                  </Switch>
                </Suspense>

                <Suspense fallback={PopupLoading}>
                  <Switch>
                    <Route path="/importStatus">
                      <ForceLogin>
                        <ImportStatus />
                      </ForceLogin>
                    </Route>
                    <Route path="*/importStatus">
                      <ForceLogin>
                        <ImportStatus />
                      </ForceLogin>
                    </Route>
                    <Route>
                      {loggedInAndEncryptionKeyReady && (
                        <Suspense fallback={null}>
                          <ImportQuickView sideBarWidth={sideBarWidth} />
                        </Suspense>
                      )}
                    </Route>
                  </Switch>
                </Suspense>
                {loggedInAndEncryptionKeyReady && isMobile && showBottomBar && (
                  <Suspense fallback={null}>
                    <BottomBar items={bottomBarItems} />
                  </Suspense>
                )}
              </Content>
            </Wrapper>
          </Page>
        )}
      </AppDiv>
    )
  }
}
App.propTypes = {
  loggedIn: PropTypes.bool,
  userId: PropTypes.string,
  hideMyProjects: PropTypes.bool,
  userDataEncryptionKeyAvailable: PropTypes.bool,
  gettingEncryptionKeyForUserData: PropTypes.bool,
  userAvailable: PropTypes.bool,
  tokenAvailable: PropTypes.bool,
  getUser: PropTypes.func.isRequired,
  user: PropTypes.object,
  loadOwnProjects: PropTypes.func.isRequired,
  passwordPolicy: PropTypes.shape({
    sms: PropTypes.bool.isRequired,
    otp: PropTypes.bool.isRequired,
  }),
  showPopupOverlay: PropTypes.bool,
  showMobilePopupOverlay: PropTypes.bool,
  menuOpen: PropTypes.bool,
  privacyPolicyAccepted: PropTypes.bool,
  termsAndConditionsAccepted: PropTypes.bool,
  toggleMenu: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  resize: PropTypes.func.isRequired,
  currentRoute: PropTypes.string.isRequired,
  currentRouteHref: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  hidePopupOverlay: PropTypes.func.isRequired,
  hideMobilePopupOverlay: PropTypes.func.isRequired,
  getTokenByRefreshToken: PropTypes.func.isRequired,
  license: PropTypes.object,
  expiredToken: PropTypes.bool,
  showLoginPopup: PropTypes.bool,
  windowWidth: PropTypes.number,
  showBottomBar: PropTypes.bool,
  gettingUser: PropTypes.bool,
  browserInfo: PropTypes.object,
  loggingIn: PropTypes.bool,
  projectDetails: PropTypes.shape(),
  plan: PropTypes.shape(),
  pin: PropTypes.shape(),
  intl: PropTypes.object,
}

const mapStateToProps = createStructuredSelector({
  loggedIn: makeSelectLoggedIn(),
  loggingIn: makeSelectloggingIn(),
  userDataEncryptionKeyAvailable: makeSelectUserDataEncryptionKeyAvailable(),
  gettingEncryptionKeyForUserData: makeSelectGettingEncryptionKeyForUserData(),
  userAvailable: makeSelectUserAvailable(),
  privacyPolicyAccepted: makeSelectPrivacyPolicyAccepted(),
  termsAndConditionsAccepted: makeSelectTermsAndConditionsAccepted(),
  passwordPolicy: makeSelectPasswordPolicy(),
  tokenAvailable: makeSelectTokenAvailable(),
  showPopupOverlay: selectors.makeSelectShowPopupOverlay(),
  showMobilePopupOverlay: selectors.makeSelectShowMobilePopupOverlay(),
  menuOpen: selectors.makeSelectMenuOpen(),
  currentRoute: selectors.makeSelectCurrentRoute(),
  currentRouteHref: selectors.makeSelectCurrentRouteHref(),
  isMobile: selectors.makeSelectIsMobile(),
  isDesktop: selectors.makeSelectIsDesktop(),
  urlQuery: selectors.makeSelectCurrentQuery(),
  license: makeSelectLicense(),
  userPermissions: makeSelectPermissions(),
  hideMyProjects: makeSelectHideMyProjects(),
  gettingUser: makeSelectGettingUser(),
  user: makeSelectUser(),
  userId: makeSelectUserId(),
  expiredToken: makeSelectExpiredToken(),
  showLoginPopup: makeSelectShowLoginPopup(),
  windowWidth: selectors.makeSelectWindowWidth(),
  showBottomBar: selectors.makeSelectShowBottomBar(),
  browserInfo: selectors.makeSelectBrowserInfo(),
  projectDetails: makeSelectProjectDetails(),
  plan: makeSelectPlan(),
  pin: makeSelectPin(),
})

function mapDispatchToProps(dispatch) {
  return {
    getUser: () => dispatch(getUser()),
    loadOwnProjects: () => dispatch(loadOwnProjects()),
    toggleMenu: () => dispatch(actions.toggleMenu()),
    closeMenu: () => dispatch(actions.closeMenu()),
    resize: (...attributes) => dispatch(actions.resize(...attributes)),
    getTokenByRefreshToken: (...attributes) =>
      dispatch(getTokenByRefreshToken(...attributes)),
    hidePopupOverlay: (...attributes) =>
      dispatch(actions.hidePopupOverlay(...attributes)),
    hideMobilePopupOverlay: (...attributes) =>
      dispatch(actions.hideMobilePopupOverlay(...attributes)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(App))
