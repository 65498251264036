import * as actionTypes from './constants'

export function login(email, password) {
  return {
    type: actionTypes.LOGIN,
    email,
    password,
  }
}

export function twoFaLogin(email, password, twoFaCode, twoFaType) {
  return {
    type: actionTypes.TWOFA_LOGIN,
    email,
    password,
    twoFaCode,
    twoFaType,
  }
}

export function logout() {
  return {
    type: actionTypes.LOGOUT,
  }
}

export function loginSuccessful(token) {
  return {
    type: actionTypes.LOGIN_SUCCESSFUL,
    token,
  }
}

export function loginError(error) {
  return {
    type: actionTypes.LOGIN_ERROR,
    error,
  }
}

export function setEmail(email) {
  return {
    type: actionTypes.SET_EMAIL,
    email,
  }
}

export function setPassword(password) {
  return {
    type: actionTypes.SET_PASSWORD,
    password,
  }
}

export function setUser(user) {
  return {
    type: actionTypes.SET_USER,
    user,
  }
}

export function updateUser(user) {
  return {
    type: actionTypes.UPDATE_USER,
    user,
  }
}
export function setUserError(error) {
  return {
    type: actionTypes.SET_USER_ERROR,
    error,
  }
}
export function getUser() {
  return {
    type: actionTypes.GET_USER,
  }
}
export function getUserDone() {
  return {
    type: actionTypes.GET_USER_DONE,
  }
}

export function show2FA(status) {
  return {
    type: actionTypes.SHOW_2FA,
    status,
  }
}
export function setPasswordPolicy(status) {
  return {
    type: actionTypes.SET_PASSWORD_POLICY,
    status,
  }
}

export function setTwoFaCode(twoFaCode) {
  return {
    type: actionTypes.SET_TWOFA_CODE,
    twoFaCode,
  }
}

export function backToLogin() {
  return {
    type: actionTypes.BACK_TO_LOGIN,
  }
}

export function verifyEmail(token) {
  return {
    type: actionTypes.VERIFY_EMAIL,
    token,
  }
}
export function verifyEmailDone() {
  return {
    type: actionTypes.VERIFY_EMAIL_DONE,
  }
}
export function verifyEmailWrongEmail() {
  return {
    type: actionTypes.VERIFY_EMAIL_WRONG_EMAIL,
  }
}
export function verifyEmailError(error) {
  return {
    type: actionTypes.VERIFY_EMAIL_ERROR,
    error,
  }
}

export function loginViaSaml(token) {
  return {
    type: actionTypes.LOGIN_VIA_SAML,
    token,
  }
}
export function loginViaSamlError(error) {
  return {
    type: actionTypes.LOGIN_VIA_SAML_ERROR,
    error,
  }
}
export function samlLoginDone() {
  return {
    type: actionTypes.LOGIN_VIA_SAML_DONE,
  }
}

export function getEncryptionKeyForUserData() {
  return {
    type: actionTypes.GET_ENCRYPTION_KEY_FOR_USER_DATA,
  }
}
export function getEncryptionKeyForUserDataDone(key) {
  return {
    type: actionTypes.GET_ENCRYPTION_KEY_FOR_USER_DATA_DONE,
    key,
  }
}
export function getEncryptionKeyForUserDataError(error) {
  return {
    type: actionTypes.GET_ENCRYPTION_KEY_FOR_USER_DATA_ERROR,
    error,
  }
}

export function hideMyProjects() {
  return {
    type: actionTypes.HIDE_MY_PROJECTS,
  }
}
export function showMyProjects() {
  return {
    type: actionTypes.SHOW_MY_PROJECTS,
  }
}

export function loginWithRedirect(email, password) {
  return {
    type: actionTypes.LOGIN_WITH_REDIRECT,
    email,
    password,
  }
}

export function loginWithRedirectSuccessful(token) {
  return {
    type: actionTypes.LOGIN_WITH_REDIRECT_SUCCESSFUL,
    token,
  }
}

export function expiredToken(status) {
  return {
    type: actionTypes.EXPIRED_TOKEN,
    status,
  }
}

export function showLoginPopup(status) {
  return {
    type: actionTypes.SHOW_LOGIN_POPUP,
    status,
  }
}

export function getTokenByRefreshToken() {
  return {
    type: actionTypes.GET_TOKEN_BY_REFRESH_TOKEN,
  }
}

export function getTokenByRefreshTokenDone(token) {
  return {
    type: actionTypes.GET_TOKEN_BY_REFRESH_TOKEN_DONE,
    token,
  }
}

export function getTokenByRefreshTokenError(error) {
  return {
    type: actionTypes.GET_TOKEN_BY_REFRESH_TOKEN_ERROR,
    error,
  }
}
