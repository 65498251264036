/* eslint-disable no-cond-assign */
import { v4 as uuid } from 'uuid'

export default () => {
  let id
  if (!(id = localStorage.getItem('browserUUID'))) {
    localStorage.setItem('browserUUID', (id = uuid()))
  }
  return id
}
