import config from '.'

const {
  url,
  apiv2,
  fcmSubscription,
  pdf2jpegBucket,
  templateV3,
  projectsApi,
  pinsApi,
  plansApi,
  planCompareBucket,
  assigneesApi,
} = config

export const v2URL = `${url}${apiv2}`
export const pdf2jpegBucketPath = pdf2jpegBucket
export const planCompareBucketPath = planCompareBucket
export const pinsAPI = `${url}${pinsApi.path}${pinsApi.version}/pins`
export const projectsAPI = `${url}${apiv2}/projects`
export const templateV2API = `${v2URL}/templates`
export const templateV3API = `${url}${templateV3.version}${templateV3.path}`
export const mediaExportAPI = `${v2URL}/mediaExport`
export const reportsAPI = `${v2URL}/reports`
export const projectsV2API = `${v2URL}/projects`
export const projectsRefreshAPI = `${v2URL}/sw-refresh/projects`
export const projectsV3API = `${url}/${projectsApi.path}${projectsApi.version}`
export const assigneesV4API = `${url}${assigneesApi.path}${assigneesApi.version}`
export const projectsFolderAPI = `${projectsV3API}/folders/children`
export const uploadFileAPI = `${v2URL}/uploadUrl`
export const projectsFolderRefreshAPI = `${url}/sw-refresh${projectsApi.path}${projectsApi.version}/folders/children`
export const meAPI = `${v2URL}/me`
export const meV3API = `${url}/api/v3/me`
export const myAvatarAPI = `${meAPI}/avatar`
export const usersAPI = `${v2URL}/users`
export const contactsAPI = `${v2URL}/contacts`
export const filterAPI = `${v2URL}/filters`
export const avatarSuffix = 'avatar'
export const plansAPI = `${v2URL}/plans`
export const datasetsApi = `${v2URL}/datasets`
export const planRevisionsAPI = `${v2URL}/plans/revisions`
export const planRevisionsPath = `${apiv2}/plans/revisions`
export const planComparisonApi = `${v2URL}/plan-comparison`
export const plansV3API = (ref) =>
  `${url}${ref ? 'sw-refresh' : ''}${plansApi.path}${plansApi.version}`
export const plansV3Projects = (ref) => `${plansV3API(ref)}/projects`
export const plansV3Folders = (ref) => `${plansV3API(ref)}/folders`
export const projectV3Api = (ref) =>
  `${v2URL}${ref ? '/sw-refresh' : ''}/projects-api/v3`
export const projectTreeFolderApi = (ref) => `${projectV3Api(ref)}/folders`
export const pinsV2API = `${v2URL}/pins`
export const postsV2API = `${v2URL}/posts`
export const mediaV2API = `${v2URL}/media`
export const issuesV2API = `${v2URL}/issues`
export const filesAPI = `${v2URL}/files`
export const pinFieldsV2API = `${v2URL}/pin-designs`
export const planinspectorAPI = `${v2URL}/planinspector`
export const pdf2jpegAPI = `${v2URL}/pdf2jpeg`
export const devicesAPI = `${v2URL}/limited-devices`
export const suggestionAPI = `${v2URL}/suggestions/topics`
export const encryptionKeyAPI = `${v2URL}/encryptionKey`
export const thumbnailsExt = '/thumbnails.jpg'
export const thumbnailExt = '/thumbnail.jpg'
export const featureImage = '/featureImage'
export const logo = '/logo'
export const GET_PROJECT_DETAILS_OPTIONS = '?expand=Permissions,Categories'
export const PINS = '/pins'
export const COMMENTS = '/comments'
export const SEARCH_STATE_FILTER_ACTIVE_SIZE_100 =
  '?stateFilter=Active&size=100'
export const ALL_ASSIGNEES = '/assignees/all'
export const ASSIGNEES = '/assignees'
export const PAGED_POSTS = '/posts/paged'
export const SW_REFRESH = '/sw-refresh'
export const SEARCH_INCLUDE_REMOVED = '?includeRemoved=true'
export const fcmSubscriptionAPI = (token) =>
  `${fcmSubscription.url}${fcmSubscription.prefix}/${token}${fcmSubscription.suffix}`
export const mapboxGeocodeUrl =
  'https://api.mapbox.com/geocoding/v5/mapbox.places'
