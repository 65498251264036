import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { ifNotProp, ifProp, prop, withProp } from 'styled-tools'
import { Savebtn } from '../../containers/ProjectSettings/styledComponents'
import { popupSize } from './constants'
import Button from '../Button'
import { InlineLoader } from '../PageLoader'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'
import {
  sizing,
  weights,
} from '../stories/fundamentals/text-styles/text-styles'

export const PopupOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colorsOptions.greys['grey-100']};
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: ${ifProp('overPopup', 9999, 9997)};
  opacity: 0.5;
  top: 0;
  left: 0;
  ${({ showTopBar }) => showTopBar && 'top: 50px'}
`

export const PopupWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: ${ifProp('overPopup', 10000, 9998)};
  width: 100%;
  height: 100%;
  position: fixed;
  padding: ${ifProp('trueCenter', 0, '5% 5% 2% 5%')};

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 5% 0 0 0;
  }
  @media (min-width: 1024px) {
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
  }

  ${ifProp(
    { size: popupSize.X_LARGE },
    `
     padding-left: 0%;
  `,
  )}
`

export const Popup = styled.div`
  position: relative;
  background: ${colorsOptions.greys['grey-10']};
  z-index: 9999;
  padding: 0;
  //border: 1px solid ${colorsOptions.greys['grey-30']};
  border: none;
  border-radius: 8px;
  box-shadow: 0 18px 36px rgba(0, 0, 0, 0.26);

  @media (max-width: 767px) {
    width: 98%;
    max-width: 98%;
  }
  ${({ thicker }) => thicker && 'border-width: 2px;'}

  ${ifProp(
    { size: popupSize.X_LARGE },
    `
    max-width:97%;
    width:97%;
  `,
  )}
  ${({ size }) =>
    size === popupSize.LARGE &&
    `
    max-width:80%;
    width:80%;
  `}
  ${({ size }) =>
    size === popupSize.MEDIUM &&
    `
    max-width:770px;
    width:100%;
  `}
  ${({ size }) =>
    size === popupSize.SMALL &&
    `
    max-width:440px;
    width:100%;
  `}
  ${({ width }) =>
    width &&
    `
    max-width:${width}px;
  `}
`

export const TitleBar = styled.div`
  position: relative;
  height: 48px;
  //background: ${colorsOptions.greys['grey-10']};
  padding: 16px 10px 0 16px;
  border-bottom: 1px solid ${colorsOptions.greys['grey-40']};
  display: ${ifNotProp('values', 'flex')};
  border-radius: 8px 8px 0 0;
  justify-content: space-between;
  .title {
    ${weights['text-700']};
    color: ${colorsOptions.greys['grey-100']};
    ${sizing['text-l']};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .edit {
    ${weights['text-500']};
    padding-right: 5px;
  }
  ${ifProp(
    'iconRight',
    css`
      padding-right: 48px;
    `,
  )}
`

export const MainFooter = styled.div`
  padding: 16px;
  bottom: 0;
  //background-color: ${prop('bgColor') || colorsOptions.greys.white};
  display: flex;
  border-radius: 0 0 8px 8px;
  justify-content: ${ifProp('centralizeButtons', 'center', 'space-between')};
  @media (max-width: 767px) {
    z-index: 9999;
    flex-direction: initial;
  }
  ${withProp(['size', 'showFooterBorder'], (size, showFooterBorder) =>
    showFooterBorder
      ? `border-top: 1px solid ${colorsOptions.greys['grey-40']};`
      : '',
  )}
`

export const CloseLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 48px;
  z-index: 2;
`

export const LeftWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
export const RightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

export const Body = styled.div`
  position: relative;
  //background-color: ${prop('bgColor', colorsOptions.greys.white)};
  min-height: 50px;
  padding: ${prop('bodyPadding')};
  z-index: 1;
`

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > ${InlineLoader} {
    height: 20px;
    width: 20px;
  }
`

export const ActionButtonWrapper = styled.div`
  position: relative;
`
export const Custombtn = styled(Savebtn)`
  margin-right: ${prop('marginRight', '10px')};
  ${({ size }) =>
    size === 'SMALL' ? 'padding:10px 20px;' : 'padding:10px 30px;'}
  ${({ buttonType }) =>
    buttonType === 'CANCEL' && `color: ${colorsOptions.greys['grey-60']};`}
  ${({ buttonType }) =>
    buttonType === 'DELETE' &&
    `color: ${colorsOptions.secondaryColors.red.strong}; border: 1px solid ${colorsOptions.secondaryColors.red.normal}`}
  ${ifProp(
    'pending',
    `
    pointer-events: none;
  `,
  )}
  & > span {
    word-break: normal;
    ${ifProp('pending', 'visibility: hidden;')}
  }
  @media (max-width: 767px) {
    margin-bottom: 10px;
    min-width: 100%;
  }
`

const CustomLink = Savebtn.withComponent('a')

export const DownloadButton = styled(CustomLink)`
  margin-right: 10px;
  ${({ size }) =>
    size === 'SMALL' ? 'padding:10px 20px;' : 'padding:10px 40px;'}
  ${({ buttonType }) =>
    buttonType === 'CANCEL' && `color:${colorsOptions.greys['grey-60']};`}
 ${ifProp('pending', 'pointer-events: none;')}
 text-decoration: none;
  @media (max-width: 767px) {
    margin-bottom: 10px;
    min-width: 100%;
  }
`

export const RoundIconButton = styled(Button)`
  width: 40px;
  height: 40px;
  text-decoration: none;
  margin: 0 4px;
  box-shadow: 0 1px 5px 0 rgba(20, 26, 33, 0.2);
  border: none;
  padding: 0;
  ${ifProp(
    'pending',
    `
  pointer-events: none;
 `,
  )}
  &:disabled {
    svg {
      opacity: 0.3;
    }
  }
`

export const CenterText = styled.div`
  text-align: center;
`

export const ContentText = styled.div`
  padding: 16px 10px 16px 16px;
`

export const PopupInside = styled.div`
  padding: 15px 16px 15px;
  ${withProp('color', (color) => `background-color: ${color}`)}
`
export const PopupMobile = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 12px;
  padding-top: 24px;
  display: flex;
  background-color: ${colorsOptions.greys.white};
  flex-direction: column;
  width: 80%;
  border-radius: 9px;
  min-height: ${ifNotProp('size', '50vh')};
  justify-content: space-between;
  padding-right: 12px;
`
export const TitleMobile = styled.div`
  ${sizing['text-xl']};
  ${weights['text-600']};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const FooterMobile = styled.div`
  ${sizing['text-r']};
  ${weights['text-600']};
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.5em;
`
export const ContentMobile = styled.div`
  height: 80%;
`
export const ActionButtonMobileWrapper = styled.div`
  margin-left: 10%;
  ${ifProp('cancel', weights['text-500'])}
  ${ifProp(
    'disabled',
    `pointer-events: none; color: ${colorsOptions.greys['grey-60']};`,
  )}
`
