import staging from '../proxyConfig/staging'
import production from '../proxyConfig/production'

const { VITE_DEVSERVER_TARGET = 'staging', PORT = 5173 } = import.meta.env
const domain = `http://localhost:${PORT}`

const dev = {
  url: `${domain}/devserver`,
  reports: `${domain}/devserver`,
  planImport: {
    url: `${domain}/planImportProxy`
  },
  generateEncryptionKey: {
    url: `${domain}/generateEncryptionKeyProxy`
  },
  suggestionApi: {
    url: `${domain}/suggestionApiProxy`
  },
  devicesApi: {
    url: `${domain}/devicesApiProxy`
  },
  uploadFile: {
    url: `${domain}/uploadFileProxy`
  },
  planInspector: {
    url: `${domain}/planInspectorProxy`
  },
  pdf2jpeg: {
    url: `${domain}/pdf2jpegProxy`
  },
  planCompareBucket: 'docutools-staging-plans-2',
  fcmSubscription: {
    url: 'https://iid.googleapis.com',
    prefix: '/iid/v1',
    suffix: '/rel/topics'
  }
}

const config =
  VITE_DEVSERVER_TARGET === 'production' ? production : staging

const mergedConfig = { ...config, ...dev }

export default mergedConfig
